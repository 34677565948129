<template>
    <div class="banner">
        <v-img class="showImg" :src="$utils.formatImgUrl(data.imgUrl)"></v-img>
        <div class="img hidden-md-and-up"
             :style="{'background-image': 'url(' +`${data.wrapImg?$utils.formatImgUrl(data.wrapImgUrl):$utils.formatImgUrl(data.imgUrl)}` + ')'}"></div>
        <div class="banner-title white--text text-center">
            {{data.title}}<br>{{data.enTitle}}
        </div>
    </div>
</template>
<script>
    export default {
        name: 'banner',
        props: {
            data: {}
        }
    }
</script>
<style lang="scss" scoped>
    .banner {
        width: 100%;
        height: 410px;
        position: relative;
        margin-top: 60px;
        .showImg {
            display: block;
            height: 100%;
        }
        .banner-title {
            @include ct();
            width: 100%;
            line-height: 53px;
            font-size: 40px;
        }
    }

    @media (max-width: $screen-md) {
        .banner {
            height: 300px;
            margin-top: 0;
            .showImg {
                display: none;
            }
            .banner-title {
                font-size: 20px;
                line-height: 27px;
            }
            .img {
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
            }
        }
    }

    @media (max-width: $screen-sm) {
        .banner {
            height: 240px;
            .showImg {
                display: none;
            }
            .banner-title {
                font-size: 16px;
                line-height: 23px;
            }
        }
    }
</style>